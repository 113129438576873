import React from "react";


const containerStyle = {
  width: "100%",
  height: "300px",
};

const center = {
  lat: 40,
  lng: -70,
};
const GoogleMapComponent = () => {
  return (
    // <LoadScript googleMapsApiKey={process.env.GOOGLE_MAPS_API_KEY}>
    //   <GoogleMap
    //     mapContainerStyle={containerStyle}
    //     center={center}
    //     zoom={15}
    //   >
    //     <Marker position={center} />
    //   </GoogleMap>
    // </LoadScript>


<div style={{ width: '100%', height: '400px' }}>
<iframe
  title="Google Map"
  width="100%"
  height="100%"
  style={{ border: '2px solid red' }} 
  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3982.9284245365848!2d29.391465074731403!3d-3.3676729966070083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zM8KwMjInMDMuNiJTIDI5wrAyMyczOC41IkU!5e0!3m2!1sen!2sca!4v1686238987169!5m2!1sen!2sca"
  allowFullScreen
></iframe>
{/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d31862.76393149396!2d29.349687108047252!3d-3.3878864406255778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1soasi%20orphanage%20bujumbura!5e0!3m2!1sen!2sca!4v1682109101020!5m2!1sen!2sca" width="400" height="100% " style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
</div>

  );
};
export default GoogleMapComponent;
