import React, { useState, useEffect } from 'react';
//import './Testimonials.css';
import Card from 'react-bootstrap/Card';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import CurvedIframe from '../../../CurvedIframe/CurvedIframe';

function Sisteridatestimony (){
  const {t, i18n} = useTranslation();
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined') {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const aspectRatio = 16 / 9; // 16:9 aspect ratio
        let scalingFactor = 0.6; // Default scaling factor

        // Adjust the scaling factor for mobile screens
        if (screenWidth < 768) {
          scalingFactor = 0.9; // Increase the scaling factor for mobile screens
        }

        // Calculate the video width and height based on the screen size and aspect ratio
        let videoWidth, videoHeight;
        if (screenWidth / screenHeight > aspectRatio) {
          videoHeight = screenHeight * scalingFactor;
          videoWidth = videoHeight * aspectRatio;
        } else {
          videoWidth = screenWidth * scalingFactor;
          videoHeight = videoWidth / aspectRatio;
        }

        // Update the video size state
        setVideoSize({ width: videoWidth, height: videoHeight });
      }
    };

    // Call the handleResize function on initial render and window resize
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const videos = t("Videos_Testimonials", { returnObjects: true });
  const video0 = videos[0];
  return (

    <div>
    <Container>
      <Row className="d-flex justify-content-center">
        <Card className="custom-class">
          <Card.Body>
                <Card.Title><strong>Sister Ida</strong></Card.Title>
                <div className='video-wrapper'>
                <CurvedIframe
                  src={video0.src}
                  // frameborder="0"
                  // allow="autoplay; encrypted-media;fullscreen"
                  // allowfullscreen
                  title="video"
                  width={videoSize.width}
                  height={videoSize.height}
                />
                </div>{" "}
                <Card.Text>
                {video0.subtitle}
                </Card.Text>
                {/* <Button variant="primary" style={{ textAlign: 'center' }} onClick={settingParagraph}>{buttonText}</Button> */}
              </Card.Body>
            </Card>

          

        </Row>
      </Container>
      </div>
  )
}

export default Sisteridatestimony;