import React from "react";
import './annualreportlist.css';
import { useTransition, useTranslation } from "react-i18next";
import { Container, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
//import { Link } from "react-router-dom/cjs/react-router-dom.min";


function AnnualReportList() {
  const { t, i18n } = useTranslation();
  const rows = t("annualreportobject", { returnObjects: true });
  return (
    <Container fluid className="d-flex justify-content-center align-items-center" >
      <div className="table-container">
        <Table bordered hover>
          <thead>
            <tr className="table-heading">
              <th>{t("serialnumber")}</th>
              <th>{t("names")}</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {rows.map((row) => (
              <tr className="table-row" key={row.id}>
                <td>{row.id}</td>
                <td><Link to="/audit">{row.name}</Link></td>
              </tr>
            ))}
          </tbody>
        </Table>

      </div>
    </Container>
  );
}
export default AnnualReportList;