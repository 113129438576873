import React from "react";
import './school.css';
import Card from 'react-bootstrap/Card';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from 'react-bootstrap';
import CurvedIframe from "../../CurvedIframe/CurvedIframe";
export default function schools() {
  const { t, i18n } = useTranslation();
  const videos_school = t('videos_school', { returnObjects: true });
  return (

    <div className="schools" >
      <div className='title'><h3><strong>{t("nyambuyeproject")}</strong></h3></div>


      <Container>
        <Row className="d-flex justify-content-center ">

          {videos_school.map((video, index) => (
            <Col key={index} className="d-flex justify-content-center">
              <Card className={"custom-class custom-card-spacing "} style={{ width: '22rem' }}>

                <Card.Body>
                  <Card.Title className='custom-cardtitle'>{video.title}</Card.Title>
                  {/* <iframe
                    src={video.src}
                    frameborder="0"
                    allow="autoplay; encrypted-media;fullscreen"
                    allowfullscreen
                    title="video"
                    width={300} height={168.75}
                  />{" "} */}

                  <div className="video-wrapper">
                    <CurvedIframe
                    src ={video.src}
                    title="video"
                    width={300}
                    height={168.75}
                    />
                   
                  </div>
                  <Card.Text className='custom-cardtext'>
                    {video.subtitle}
                  </Card.Text>
                  {/* <Button variant="primary" style={{ textAlign: 'center' }} onClick={settingParagraph}>{buttonText}</Button> */}
                </Card.Body>
              </Card>
            </Col>
          ))}

        </Row>
      </Container>
    </div>

  )
}