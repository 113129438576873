import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n';
import Cookies from 'js-cookie';
import i18n from "i18next";

const language = Cookies.get("i18next");// checking the language in cookies
if (language) {
    i18n.changeLanguage(language);
}
ReactDOM.render(
    <React.StrictMode>
        <React.Suspense fallback="Loading...">
            <App />
        </React.Suspense>
    </React.StrictMode>,
    document.querySelector('#root')
);



