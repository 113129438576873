import React from "react";
import './Vision.css';
import { useTransition, useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";


function Vision() {
  const { t, i18n } = useTranslation();
  return (
    <Container fluid className="visionbody">


      <div className="visionbody">
        <h4 className="Vision_text">{t("Vision")}</h4>
        <div className="Center_text">
          <div>{t("Vision_Paragraph")}</div>
        </div>

        <h4>{t("Mission")}</h4>
        <div className="Center_text">
          <div>{t("Mission_Paragraph")}</div>
        </div>


      </div>
    </Container>
  );
}
export default Vision;