import React from "react";

import { Container } from "react-bootstrap";
//import { render } from "react-dom";
import { useTranslation } from "react-i18next";
import "./Phenoma.css";
import {  Link } from 'react-router-dom';
function Phenoma(){
  const { t, i18n } = useTranslation();
  
    return(
      <div>
        <Container>
        <p><h3><strong>{t("Phen1")}</strong></h3></p>
        <p>{t("Phen3")}</p>
        <p>{t("Phen5")}</p>
        <p>{t("Phen7")}</p>
        <p>{t("Phen8")}</p>
        <p>{t("Phen10")}</p>
        <p>{t("Phen12")}</p>
        <p>{t("Phen13")}</p>
        <p>{t("Phen14")}</p>
        <p>{t("Phen15")}</p>
        <p>{t("Phen17")}<Link to="/sisteridatestimony" className="sisteridatestimony"> {t("Phen18")}?</Link>).</p>
        {/* <p>{t("Phen20")}</p>
        <p>{t("Phen21")}</p> */}
        <p>{t("Phen23")} </p>
        {/* <p>{t("Phen25")} <Link to="/frandre" className="frandrelink">{t("Phen26")}?</Link>).</p> */}

        <p>{t("Phen25")}:<Link to="/frAndre" style={{ display: 'inline' }} className="frandrelink">{t("Phen26")}?</Link>).</p>

        <p>{t("Phen28")}</p>
        </Container>
       
  
      </div>
    );
  }
  


export default Phenoma;