import React from "react";
import './workpage.css';
import { useTransition, useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";


function workpage() {
  const { t, i18n } = useTranslation();
  return (
    <Container fluid className="workbody">
      <div className="workpage">{t("workpage")}</div>
    </Container>
  );
}
export default workpage;