import React from "react";
import { Container } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

function AppFooter() {
    const { t, i18n } = useTranslation();
    return (

        <footer className="footer-bottom">
            <Container>
                <Row className="d-flex justify-content-between" >
                    <Col md={2}>
                        <div className="footer-title">{t("organization")}</div>
                        <Link to="/FooterContacts" className="footertext">{t("contact")}</Link>
                        <br />
                        <Link to="/FooterContacts" className="footertext">{t("mainorphanage")}</Link>

                    </Col>

                    <Col md={2}>
                        {/* <div className="footer-title">Other links</div>
                        <a href="https://www.archidiocesedebujumbura.bi/?ik/at858/3" target="_blank " className="footertext">Other information on Oasi</a> */}

                        <div className="footer-title">{t("resources")}</div>
                        <Link to="/otherlinks" className="footertext">{t("otherlink")}</Link>

                        <br/>
{/* 
                        <a href="https://archidiocesedebujumbura.bi/ik/at350/" target="_blank" className="footertext">More information on Oasi</a> */}
                    </Col>
                </Row>
                <div >
                    <p className="text-center">
                        &copy; {new Date().getFullYear()} Oasi Muvyeyi w'Ikigongwe, Burundi- {t("rightsreserve")}.
                    </p>
                </div>
            </Container>
        </footer>

    )
}

export default AppFooter;