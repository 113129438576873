import React from "react";
import "./audit.css";

import { useTransition, useTranslation } from "react-i18next";

function Audit() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <h4 className="headingText">{t("annualreport_1")}</h4>

      <div className="Center_text">
        <p>{t("annualreport_2")}</p>
        <p>{t("annualreport_3")}</p>
        <p>{t("annualreport_4")}</p>
      </div>

    </div>

  );
}
export default Audit;