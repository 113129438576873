import React from 'react';
//import './About.css';
function Donate(){
    return (
        <div>
            <h1>Donate</h1>
        </div>
    );
}
export default Donate;

