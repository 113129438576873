import React from "react";
import "./whyoasis.css";

import { useTransition, useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";

function WhyOasis() {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <h2 className="titleText">{t("whyoasis")}</h2>
     
    
        <p className="first_text">{t("whyoasis_1")}</p>
        
     
        <p className="second_text">{t("whyoasis_2")}</p>
       
       
        <p className="p1_text">{t("whyoasis_3")}</p>
        <p className="p1_text">{t("whyoasis_4")}</p>
        <p className="p1_text">{t("whyoasis_5")}</p>
        <p className="p1_text">{t("whyoasis_6")}</p>
        <p className="p1_text">{t("whyoasis_7")}</p>
        <p className="p1_text">{t("whyoasis_8")}</p>
        <p className="p1_text">{t("whyoasis_9")}</p>
        <p className="p1_text">{t("whyoasis_10")}</p>
        <p className="p1_text">{t("whyoasis_11")}</p>
      
       
        <p className="p2_text">{t("whyoasis_12")}</p>
        <p className="p2_text">{t("whyoasis_13")}</p>
        <p className="p2_text">{t("whyoasis_14")}</p>
      
   

    
    </Container>

  );
}
export default WhyOasis;