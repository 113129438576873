
import React from 'react';
import './CurvedIframe.css';

const CurvedIframe = ({src, title, width, height}) => {
  return (
    <div className="curved-iframe-wrapper" style={{width, height}}>
      <iframe
        className="curved-iframe"
        src={src}
        frameBorder="0"
        allow="autoplay; encrypted-media;fullscreen"
        allowFullScreen
        title={title}
        width={width}
        height={height}
      />
    </div>
  );
};

export default CurvedIframe;