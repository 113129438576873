import React from 'react';
import { Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import slide20 from "../../assets/images/Foodgiving.png";
import slide21 from "../../assets/images/orphanleft.png";
import schoolpic from "../../assets/images/classroompic.png";
import riceCultivate from "../../assets/images/paddycultivate.png";
import catacaise from "../../assets/images/catacaise.png";
import loveThem from "../../assets/images/loveThem.png";
import "./Home.css";
import { useTranslation } from "react-i18next";
import MultiCarousal from './MultiCarousal';
import Card from 'react-bootstrap/Card';
//import slide05 from "../ourwork/images/image012.jpg"
import jumbobgimage from "../../assets/Jumbotronpic_3.jpg";

function HomeCarauselComponent() {
    const { t, i18n } = useTranslation();

    return (
        <div className='hometop'>

            <Container className="mt-0" fluid style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <Row noGutters>
                    <img src={jumbobgimage} className="img-fluid" alt="Responsive image"></img>

                    <section className="introscreen">
                        <h2><strong>{t("Home_heading")}</strong></h2>
                        <h5>{t("Home_abstract")}</h5>

                    </section>
                </Row>
            </Container>

            <Container>
                <Row className="d-flex justify-content-center">

                    <Card className="custom-class" style={{ width: '18rem' }}>

                        <Card.Body>
                            <Card.Title className='cardTitle'>{t("Pic_1_Title")}<br/><span class="second-line">Second Line</span></Card.Title>
                            <div className="card-img-wrapper brighten-image">
                            <Card.Img 
                             className="card-img-top"
                             variant="top"
                             src={slide21} ></Card.Img>
                            </div>
                            <Card.Text>
                                {t("Pic_1_Description")}
                            </Card.Text>
                            {/* <Button variant="primary">See More ..</Button> */}
                        </Card.Body>
                    </Card>

                    <Card className="custom-class" style={{ width: '18rem' }}>

                        <Card.Body>
                            <Card.Title className='cardTitle'>{t("Pic_2_Title")}<br/><span class="second-line">Second Line</span></Card.Title>
                            <div className="card-img-wrapper brighten-image">
                            <Card.Img 
                             className="card-img-top"
                             variant="top"
                             src={slide20} ></Card.Img>
                            </div>
                            <Card.Text>
                                {t("Pic_2_Description")}
                            </Card.Text>
                            {/* <Button variant="primary">See More ..</Button> */}
                        </Card.Body>
                    </Card>
                    <Card className="custom-class" style={{ width: '18rem' }}>

                        <Card.Body>
                            <Card.Title className='cardTitle'>{t("Pic_3_Title")}<br/><span class="second-line">Second Line</span></Card.Title>
                            <div className="card-img-wrapper brighten-image">
                            <Card.Img 
                             className="card-img-top"
                             variant="top"
                             src={schoolpic} ></Card.Img>
                            </div>
                            <Card.Text>
                                {t("Pic_3_Description")}
                            </Card.Text>
                            {/* <Button variant="primary">See More ..</Button> */}
                        </Card.Body>
                    </Card>
                    <Card className="custom-class" style={{ width: '18rem' }}>

                        <Card.Body>
                            <Card.Title className='cardTitle'>{t("Pic_4_Title")}</Card.Title>
                            <div className="card-img-wrapper brighten-image">
                            <Card.Img  className="card-img-top"
                             variant="top"
                             src={riceCultivate} ></Card.Img>
                            </div>
                            <Card.Text>
                                {t("Pic_4_Description")}
                            </Card.Text>
                            {/* <Button variant="primary">See More ..</Button> */}
                        </Card.Body>
                    </Card>
                    <Card className="custom-class" style={{ width: '18rem' }}>

                        <Card.Body>
                            <Card.Title className='cardTitle'>{t("Pic_5_Title")}<br/><span class="second-line">Second Line</span></Card.Title>
                            <div className="card-img-wrapper brighten-image">
                            <Card.Img 
                             className="card-img-top"
                             variant="top"
                             src={catacaise} ></Card.Img>
                            </div>
                            <Card.Text>
                                {t("Pic_5_Description")}
                            </Card.Text>
                            {/* <Button variant="primary">See More ..</Button> */}
                        </Card.Body>
                    </Card>
                    <Card className="custom-class" style={{ width: '18rem' }}>

                        <Card.Body>
                            <Card.Title className='cardTitle'>{t("Pic_6_Title")}<br/><span class="second-line">Second Line</span></Card.Title>
                            <div className="card-img-wrapper brighten-image">
                            <Card.Img 
                             className="card-img-top"
                             variant="top"
                             src={loveThem} ></Card.Img>
                            </div>
                            <Card.Text>
                                {t("Pic_6_Description")}
                            </Card.Text>
                            {/* <Button variant="primary">See More ..</Button> */}
                        </Card.Body>
                    </Card>

                </Row>
            </Container>
            <Container fluid style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <Row noGutters >

                    <div className="phenomena">
                        <h2><strong>{t("Phen1")}</strong></h2>
                        <h5>{t("Phen3")}</h5>
                        <h5>{t("Phen5")}</h5>
                        <h5>{t("Phen7")}</h5>
                        <h5>{t("Phen8")}</h5>
                        <div className='buttonMore'>
                            <Link to="/phenoma" className="phenoma-see-more-button" >{t("readmore")}</Link>
                        </div>

                    </div>

                </Row>
            </Container>
            <Container>
                <div className="row">
                    <MultiCarousal />

                </div>
            </Container>

        </div>
    );
}

export default HomeCarauselComponent;

