import React from 'react';
import '../pages/about/About.js';
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';
import { DropdownSubmenu, NavDropdownMenu } from 'react-bootstrap-submenu';
import './NavbarItems.css';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../languageTool/LanguageSwitcher.js';
import "bootstrap/dist/css/bootstrap.min.css";
//import JumbotronImage from '../DesignFurther/JumbotronImage';
//import LogOut from '../pages/Authentication/Logout.js';
import '../pages/Archdiocese/Archdiocese.js';
//import workpage from '../pages/workpage/workpage.js';

function NavbarItems() {
  const { t, i18n } = useTranslation();
  return (
    <div className="NavbarItems navbar">
      <Navbar bg="myNavBackground" variant="dark" fixed="top" expand="sm" className="mb-0">
        <Container>
          <Navbar.Brand>
            <Nav.Link href="Home"><h2>{t("headtext")}</h2></Nav.Link>

            {/* <h2>Oasi Muvyeyi w'Ikigongwe</h2> */}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto" >
              <Nav.Link href="Home">{t("home_name")}</Nav.Link>
              <NavDropdownMenu title={t("About")} id="about-nav-dropdown" className='nav-dropdown' alignRight>
                <NavDropdown.Item href="Vision">{t("Vision_Mission")}</NavDropdown.Item>
                <NavDropdown.Divider />
                {/* <NavDropdown.Item href="#mission">{t("Mission")}</NavDropdown.Item>
                <NavDropdown.Divider /> */}
                <NavDropdown.Item href="FrAndre">{t("Who_Father_Andre")}</NavDropdown.Item>
                <NavDropdown.Item href="workpage">{t("History")}</NavDropdown.Item>
                <NavDropdown.Item href="annualreportlist">{t("Annual_Reports")}</NavDropdown.Item>

                <NavDropdown.Item href="Spiritualite">{t("Spirituality")}</NavDropdown.Item>
                <NavDropdown.Item href="workpage">{t("Sister's_Witness")}</NavDropdown.Item>
                <NavDropdown.Item href="workpage">{t("Board_Directors")}</NavDropdown.Item>
                {/* <DropdownSubmenu title={t("International_Associations")} id="collapsible-nav-dropdown">
                  <NavDropdown.Item href="workpage">{t("Italy")}</NavDropdown.Item>
                  <NavDropdown.Item href="workpage">{t("France")}</NavDropdown.Item>
                  <NavDropdown.Item href="workpage">{t("Switzerland")}</NavDropdown.Item>
                  <NavDropdown.Item href="workpage">{t("Canada")}</NavDropdown.Item>
                  <NavDropdown.Item href="workpage">{t("Archdiocese_Bujumbura")}</NavDropdown.Item>
                  <DropdownSubmenu href="#Others" title={t("Others")}>
                    <NavDropdown.Item href="workpage">{t("Archdiocese_Bujumbura")}</NavDropdown.Item>
                    <NavDropdown.Item href="workpage">{t("Donor_2")}</NavDropdown.Item>
                    <NavDropdown.Item href="workpage">{t("Donor_3")}</NavDropdown.Item>
                  </DropdownSubmenu>
                </DropdownSubmenu> */}
                <NavDropdown.Item href="bujumburaarchdiocese">{t("Archdiocese_Bujumbura")}</NavDropdown.Item>
              </NavDropdownMenu>
              <Nav.Link href="testimonials">{t("Testimonials")}</Nav.Link>
              <NavDropdownMenu title={t("projects")} id="collapsible-nav-dropdown" className='nav-dropdown' alignRight>
                <DropdownSubmenu href="#projectCur" title={t("current_projects")}>
                  {/* <NavDropdown.Item href="#School1">{t("School")} / {t("Sidewalk")}</NavDropdown.Item> */}
                  <NavDropdown.Item href="workpage">{t("School")} </NavDropdown.Item>
                  {/* <NavDropdown.Item href="#School3">{t("Medclinik")}</NavDropdown.Item> */}
                  {/* <NavDropdown.Item href="workpage">{t("Schresidence")}</NavDropdown.Item> */}
                  {/* <NavDropdown.Item href="#School5">{t("Staff_school")}</NavDropdown.Item>
                  <NavDropdown.Item href="#School6">{t("Miniproject")}</NavDropdown.Item> */}
                </DropdownSubmenu>
                <NavDropdown.Divider />
                <DropdownSubmenu href="#projectComp" title={t("Compprojects")}>
                  <NavDropdown.Item href="completedprojectbujumbura">{t("Orp_resident")}</NavDropdown.Item>
                  <NavDropdown.Item href="workpage">{t("Sis_noviciat")}</NavDropdown.Item>
                  <NavDropdown.Item href="workpage">{t("Chapel")}</NavDropdown.Item>
                  <NavDropdown.Item href="workpage">{t("Land_Purchase")}</NavDropdown.Item>
                  <NavDropdown.Item href="workpage">{t("Mini_Store")}</NavDropdown.Item>
                </DropdownSubmenu>
                <DropdownSubmenu href="#projectCur" title="Future Projects">
                  <NavDropdown.Item href="workpage">Medical Clinic</NavDropdown.Item>
                  {/* <NavDropdown.Item href="#School2">{t("School")} / {t("Washroom")}</NavDropdown.Item> */}

                </DropdownSubmenu>
                <DropdownSubmenu href="#projectCur" title="Mini Projects">
                  <NavDropdown.Item href="workpage">{t("School")} </NavDropdown.Item>

                </DropdownSubmenu>
              </NavDropdownMenu>

              <Nav.Link href="workpage">{t("News")}</Nav.Link>
              <Nav.Link href="FooterContacts">{t("Contactus")}</Nav.Link>
              {/* <Nav.Link href="Login">{t("Login")}</Nav.Link>
              <Nav.Link href="Donate">{t("Donate")}</Nav.Link> */}
              <LanguageSwitcher />
              {/* <LogOut /> */}
            </Nav>
          </Navbar.Collapse>
        </Container>

      </Navbar>
    </div>
  )
}

export default NavbarItems;
