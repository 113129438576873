const IMAGES ={
    ReportImage1: require('../../assets/images/image001.jpg').default,
    ReportImage2: require('../../assets/images/image004.jpg').default,
    ReportImage3: require('../../assets/images/image007.jpg').default,
    ReportImage4: require('../../assets/images/image009.jpg').default,
    ReportImage5: require('../../assets/images/image012.jpg').default,
    ReportImage6: require('../../assets/images/image014.jpg').default,
    ReportImage7: require('../../assets/images/image016.jpg').default,
    ReportImage8: require('../../assets/images/image018.jpg').default,
    
}
export default IMAGES;