import React from "react";
import "./whofrandre.css";
import imgfr from "../../assets/pics/frandre.png";
import { useTransition, useTranslation } from "react-i18next";
import repFrench from "../../assets/pdffiles/report/10yearsrreport_French.pdf";
import repSchool from "../../assets/pdffiles/report/saint_irenee_andre_report.pdf";
import { Container } from "react-bootstrap";

function whoisfrandre() {
  const { t, i18n } = useTranslation();

  const Reports_Andre = t('Downloads_Padre_Andre_Report', {
    returnObjects: true
  });
  return (
    <div>
      <h4 className="headingText">{t("Padre_1")}</h4>
      <img src={imgfr} />
      <div className="Center_text">
        <div>{t("Padre_2")}</div>
        <div>{t("Padre_3")}</div>
        <div>{t("Padre_4")}</div>
        <p>{t("Padre_5")}</p>
        <p>{t("Padre_6")}</p>
        <p>{t("Padre_7")}</p>
        <p>{t("Padre_8")}</p>
        <p>{t("Padre_9")}</p>
        <p>{t("Padre_10")}</p>


      </div>
     <Container>
      <ol className="andrereport">
      <li className="Report_Andre">
        <a href={t('Downloads_Padre_Andre_Report.download')} target="_blank" rel="noopener noreferrer"
        > {t('Downloads_Padre_Andre_Report.title')}</a>
      </li>
      <li className="Report_Andre">
        <a href={t('Downloads_Andre_Second_Book.download')} target="_blank" rel="noopener noreferrer"> {t("Downloads_Andre_Second_Book.title")}</a>
      </li>
      <li className="Report_Andre">
        <a href={t('Downloads_Andre_First_Book.download')} target="_blank" rel="noopener noreferrer"> {t("Downloads_Andre_First_Book.title")}</a>
      </li>
      </ol>
      </Container>
    </div>

  );
}
export default whoisfrandre;