import React from 'react';
//import './About.css';
function Member(){
    return (
        <div>
          <p>
This is member Page</p>

<p>This is member Page</p>

<p> Beaucoup d&rsquo;enfants ont perdu leurs parents ou ces derniers, m&ecirc;me s&rsquo;ils vivent encore, n&rsquo;ont pas la possibilit&eacute; physique ou &eacute;conomique pour les &eacute;lever dignement.</p>

<p>Certains d&rsquo;entre eux deviennent des bandits, des d&eacute;linquants ou des sans abris que nous appelons cyniquement &laquo; les enfants de la rue &raquo;.</p>

<p>La fondation a &eacute;t&eacute; cr&eacute;&eacute;e en 2007. Elle a c&eacute;l&eacute;br&eacute; son 1er anniversaire le 13 d&eacute;cembre 2008</p>


        </div>
    );
}
export default Member;

