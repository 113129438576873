import React from "react";
import {  Container } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css"
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import GoogleMapComponent from "../GoogleMapComponent/GoogleMapComponent";
function AppFooter() {


    const { t, i18n } = useTranslation();
    return (
        <div id="footer1" className="contactus"  >
            <div >
                <div >
                    <Container>
                        <Row className="d-flex justify-content-around">
                            <Col md="auto">
                                <h3>{t("contactinformation")}</h3>
                                <div className="ContactDetails">
                                    <p>- {t("contactburundi")}<br />
                                        <ul>
                                            {t("contactmother")}
                                            <li>omikburundi@gmail.com</li>
                                        </ul>


                                        - {t("contactitaly")}<br />
                                        <ul>
                                            {t("contactpresident")}
                                            <li>rcento@oasi.en.it</li>
                                        </ul>


                                        - {t("contactswitzerland")}<br />
                                        <ul>
                                            <li>jocelynedestadelhofen@gmail.com</li>
                                        </ul>


                                        - {t("contactfrance")}<br />
                                        <ul>
                                            <li>oasismisericorde@orange.fr</li>
                                        </ul>

                                        - {t("contactcanada")}<br />
                                        <ul>
                                            {t("contactgabylegault")}
                                            <li>gabylegault@gmail.com</li>
                                        </ul>
                                    </p>
                                </div>

                            </Col>
                            <Col md="auto">
                                <h3>{t("mainorphanagemap")}, Bujumbura</h3>
                              

                                    <GoogleMapComponent />
                              
                            </Col>
                        </Row>

                    </Container>

                </div>
            </div>

        </div>

    )
}


export default AppFooter;