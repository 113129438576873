import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import slide05 from "../../assets/images/image012.jpg"
import slide06 from "../../assets/images/image014.jpg"
import slide07 from "../../assets/images/image016.jpg"
import slide08 from "../../assets/images/image018.jpg"
import slide01 from "../../assets/images/image001.jpg"
import slide02 from "../../assets/images/image004.jpg"
import slide03 from "../../assets/images/image007.jpg"
import slide04 from "../../assets/images/image009.jpg"
import './MultiCarausal.css'
function MultiCarousal() {

  const responsive = {
    largeDesktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 7,
      slidesToSlide: 1
    },    
    desktop: {
      breakpoint: { max: 1600, min: 1024 },
      items: 5,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1
    }
  };

  return (
    <Carousel
      swipeable={false}
      draggable={false}
      showDots={true}
      responsive={responsive}
      ssr={true}
      infinite={true}
      autoPlay={true} // Enable autoplay
      autoPlaySpeed={2000} // Set autoplay speed to 2 seconds
      keyBoardControl={true}
      customTransition="all 5s linear"
      transitionDuration={6000}
      containerClass="carousel-container"
      removeArrowOnDeviceType={[]}
      dotListClass="custom-dot-list-style"
      itemClass=""
      arrows // Add arrows for navigation
    >
      
       <div className="image-box">
        <img className="carousel-image" src={slide01} alt="Img1" />
      </div>
      <div className="image-box">
        <img className="carousel-image" src={slide02} alt="Img2" />
      </div>
      <div className="image-box">
        <img className="carousel-image" src={slide03} alt="Img3" />
      </div>
      <div className="image-box">
        <img className="carousel-image" src={slide04} alt="Img4" />
      </div>
      <div className="image-box">
        <img className="carousel-image" src={slide05} alt="Img1" />
      </div>
      <div className="image-box">
        <img className="carousel-image" src={slide06} alt="Img2" />
      </div>
      <div className="image-box">
        <img className="carousel-image" src={slide07} alt="Img3" />
      </div>
      <div className="image-box">
        <img className="carousel-image" src={slide08} alt="Img4" />
      </div>
    </Carousel>
  );
}


export default MultiCarousal;