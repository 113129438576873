import React from "react";
import './otherlinks.css';
import { useTransition, useTranslation } from "react-i18next";
import { Container, Table } from "react-bootstrap";


function OtherLinks() {
  const { t, i18n } = useTranslation();
  const rows = t("otherlinkstableobject", {returnObjects: true});
  return (
    <Container fluid className="d-flex justify-content-center align-items-center" >
  <div className="table-container">

  
    <Table bordered hover>
      <thead>
        <tr className="table-heading">
          <th>{t("")}</th>
          <th className="links">{t("names")}</th>
          </tr>
          </thead>
       <tbody className="table-body">
        {rows.map((row) => (
          <tr className="table-row" key={row.id}>
            <td>{row.id}</td>
            <td><a href={row.link} target="_blank" rel="noreferrer">{row.name}</a></td>
          </tr>
        ))}       
       </tbody>
    </Table>
    
      </div>
    </Container>
  );
}
export default OtherLinks;