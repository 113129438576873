import React from "react";
import "./bujumburaarchdiocese.css";

import { useTransition, useTranslation } from "react-i18next";

function BujumburaArchdiocese() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <h4 className="headingText">{t("Archdiocese_Bujumbura")}</h4>

      <div className="Center_text">
        <p>{t("archdiocesebujumbura_1")}</p>
        <p>{t("archdiocesebujumbura_2")}</p>
        
      </div>

    </div>

  );
}
export default BujumburaArchdiocese;