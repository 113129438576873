import React from "react";
import './Oasibujumbura.css';
import CurvedIframe from "../../../CurvedIframe/CurvedIframe";

import { useTranslation } from "react-i18next";
import { Card, Container, Row, Col } from "react-bootstrap";

function CompletedProjectBujumbura(){
  const {t, i18n} = useTranslation();
const Videos_CompletedBujumbura = t('Videos_CompletedBujumbura', {
  returnObjects: true
});
  return (
    <div className="ProjectBujum">
      <h4> {t("comptedprojectbujumbura")}</h4>

      <Container>
        <Row className="d-flex justify-content-center">
          {Videos_CompletedBujumbura.map((video, index) => (
             <Col key={index}  className="d-flex justify-content-center">
            <Card className="custom-class custom-card-spacing" style={{ width: '22rem'}}>
              <Card.Body>
                <Card.Title>{video.title}</Card.Title>
                {/* <iframe
                  src={video.src}
                  frameborder="0"
                  allow="autoplay; encrypted-media;fullscreen"
                  allowfullscreen
                  title="video"
                  width={300} height={168.75}
                />{" "} */}
                <div className="video-wrapper">
                  <CurvedIframe
                  src={video.src}
                  title="video"
                  width={300}
                  height={168.75}
                  />
                </div>
                <Card.Text>
                  {video.subtitle}
                </Card.Text>
              </Card.Body>
            </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default CompletedProjectBujumbura;