import React from 'react';
import NavbarItems from './components/Navbar/NavbarItems';
import './App.css';
import About from './components/pages/about/About';
import Donate from './components/pages/donate/Donate';
import Home from './components/pages/home/Home';
import Ourwork from './components/pages/ourwork/Ourwork';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { Layout } from './components/DesignFurther/Layout';
//import JumbotronImage from './components/DesignFurther/JumbotronImage';
import AppFooter from './components/Footer/Footer';
//import Signing from './components/pages/Authentication/Signing';
import Registration from './components/pages/Registration/Registration';
import Login from './components/pages/Login/Login';
import Testimonials from './components/pages/Testimonials/Testimonials';
import Member from './components/pages/Member/Member';
import Vision from './components/pages/Vision_Mission/Vision';
import FrAndre from './components/pages/Frandre/whofrandre';
import Phenoma from './components/pages/home/Phenoma';
import FooterContacts from './components/Footer/FooterContacts';
import CompletedProjectBujumbura from './components/pages/Completed_Projects/Oasis_Bujumbura/Oasibujumbura';
//import ProtectedRoute from './components/pages/Authentication/ProtectedRoute';
import Sisteridatestimony from './components/pages/Testimonials/Sisteridatestimony/Sisteridatestimony';
import Spiritualite from './components/pages/Spiritual/Spiritualite';
import schools from './components/pages/school/school';
import OtherLinks from './components/pages/OtherLinks/otherlinks';
import workpage from './components/pages/workpage/workpage';
import AnnualReportList from './components/pages/annualreports/annualreportlist';
import Audit from './components/pages/audit/audit';
import WhyOasis from './components/pages/whyoasis/whyoasis';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import ScrollToTop from './components/scolltotop/scrolltop';
import BujumburaArchdiocese from './components/pages/bujumburaarchdiocese/bujumburaarchdiocese';



function App() {

  const DefaultContainer = () => (
    <div>
      <Layout>
        <div className="App">
          <div className="page-wrapper">
            <div className="content-wrapper">
              <div className="sticky-top">
                <NavbarItems />
              </div>
              <div className='content-wrapper pt-5'>
                {/*<Route exact path="/signing" component={Signing} />*/}
                <Route path="/home" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/donate" component={Donate} />
                <Route path="/ourwork" component={Ourwork} />
                <Route path="/registration" component={Registration} />
                <Route path="/testimonials" component={Testimonials} />
                <Route path="/login" component={Login} />
                <Route path="/member" component={Member} />
                <Route path="/vision" component={Vision} />
                <Route path="/frandre" component={FrAndre} />
                <Route path="/phenoma" component={Phenoma} />
                <Route path="/footercontacts" component={FooterContacts} />
                <Route path="/completedprojectbujumbura" component={CompletedProjectBujumbura} />
                <Route path="/sisteridatestimony" component={Sisteridatestimony} />
                <Route path="/spiritualite" component={Spiritualite} />
                <Route path="/school" component={schools} />
                <Route path="/otherlinks" component={OtherLinks} />
                <Route path="/workpage" component={workpage} />
                <Route path="/annualreportlist" component={AnnualReportList} />
                <Route path="/audit" component={Audit} />
                <Route path="/whyoasis" component={WhyOasis} />
                <Route path="/bujumburaarchdiocese" component={BujumburaArchdiocese} />
              </div>
            </div>
            <AppFooter />
          </div>
        </div>
      </Layout>
    </div>
  );

  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Switch>
        <Route exact path="/">
        {/* <div className="App"> */}
        <Redirect to="/home" />
        </Route>

        <Route component={DefaultContainer} />

        {/* </div> */}
      </Switch>
    </BrowserRouter>
  );
}

export default App;
